import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import ReactGA from 'react-ga4';
import NotFound from './Pages/NotFoundPage';
import '../src/dist/styles.css';
import Home from './Pages/Home';
import AdminLogin from './module/admin/pages/AdminLogin';
import AboutUs from './Pages/AboutUs';
import BlogCompo from './Pages/Blog';
import BlogDetail from './components/BlogDetails';
import AdminRoutes from './routes/AdminRoutes';

// Firebase Configuration
var firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

function App() {
  const location = useLocation();

  // Initialize Google Analytics
  useEffect(() => {
    ReactGA.initialize("G-W99BGF6M2N");
  }, []);

  // Track page views on route change
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="blog" element={<BlogCompo />} />
      <Route path="/blog/:id" element={<BlogDetail />} />
      <Route path="admin-login" element={<AdminLogin />} />
      <Route path="admin-dashboard/*" element={<AdminRoutes />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
