import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { Avatar, Box, Typography } from "@mui/material";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";

function Testimonials() {
  const [reviews, setReviews] = useState([]);
  const firestore = getFirestore();

  // Fetch reviews from Firestore
  const fetchReviews = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(firestore, "google_Reviews")
      );
      const reviewsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReviews(reviewsList);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className='testimonials-section'>
        <div className='container'>
          <div className='testimonials-content'>
            <div className='testimonials-content__title'>
              <h2>Reviewed by Real People</h2>
              <p>
                Discover the positive impact we've made on our clients by
                reading their testimonials. We like to stay a little old school
                and do business face-to-face. Below is what our clients think of
                us.
              </p>
            </div>
            <div className='client-feedback'>
              <div className='slider-container'>
                <Slider {...settings}>
                  {reviews.map((review) => (
                    <div key={review.id}>
                      <Box
                        sx={{
                          padding: "20px",
                          textAlign: "center",
                          backgroundColor: "#fdc3230a",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                          width: "calc(100% - 30px)",
                          margin: "10px 15px",
                          minHeight: "30vh",
                        }}
                      >
                        <Avatar
                          src={review.avatar}
                          alt={review.name}
                          sx={{
                            width: 80,
                            height: 80,
                            margin: "0 auto",
                            marginBottom: "15px",
                          }}
                        />
                        <Typography variant='h6' sx={{ fontWeight: 600 }}>
                          {review.name}
                        </Typography>
                        <Typography
                          variant='body2'
                          sx={{ color: "#555", marginBottom: "10px" }}
                        >
                          {review.review}
                        </Typography>
                        <Typography sx={{ fontWeight: 600, color: "#f39c12" }}>
                          {`⭐`.repeat(review.rating)}
                        </Typography>
                      </Box>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
