import React, { useState } from 'react';
import { FaEnvelopeOpenText, FaPhoneVolume } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../images/logo/Ezcarrentals-logo.png";
 
import { getFirestore, collection, addDoc, Timestamp } from 'firebase/firestore';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
 

function Footer() {
  const emailAddress = "ezcarrentalsmelbourne@gmail.com";
  const phoneNumber = "0400 920 512";
  const firestore = getFirestore();

  const [email, setEmail] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info'); 
  const handleSubmit = async () => {
    if (!email) {
      setAlertMessage('Please enter an email address.');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    try {
      await addDoc(collection(firestore, 'subscriptions'), {
        email: email,
        timestamp: Timestamp.now(),
      });
      setAlertMessage('Subscription successful!');
      setAlertSeverity('success');
      setAlertOpen(true);
      setEmail('');
    } catch (error) {
      console.error('Error subscribing:', error);
      setAlertMessage('Subscription failed. Please try again.');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };


  return (
    <>
      <footer className='footer'>
        <div className='container'>
          <div className='footer-content'>
            <ul className='footer-content__1'>
              <li>
                <div className='navbar__img'>
                  <Link to='/' onClick={() => window.scrollTo(0, 0)}>
                    <img
                      src={Logo}
                      alt='EZ Car Rentals Logo'
                      style={{
                        width: "200px",
                        height: "auto",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                  </Link>
                </div>
              </li>
              <li>
                We offer a wide range of vehicles for all your driving needs.
              </li>
              <li>
                <a href={`tel:${phoneNumber}`}>
                  <FaPhoneVolume />
                  &nbsp;{phoneNumber}
                </a>
              </li>
              <li>
                <a href={`mailto:${emailAddress}`}>
                  <FaEnvelopeOpenText /> &nbsp;
                  {emailAddress}
                </a>
              </li>
            </ul>
            <ul className='footer-content__2'>
              <li>Working Hours</li>
              <li>
                We work only on appointment basis. But we can be reached out
              </li>
              <li>Mon - Fri: 8:00AM - 8:00PM</li>
              <li>Sat: 8:00AM - 8:00PM</li>
              <li>Sun: 8:00AM - 8:00PM</li>
            </ul>
            <ul className='footer-content__2'>
      <li>Subscription</li>
      <li>
        <p>Subscribe your Email address for the latest news & updates.</p>
      </li>
      <li>
        <input
          type='email'
          placeholder='Enter Email Address'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </li>
      <li>
        <button className='submit-email' onClick={handleSubmit}>Submit</button>
      </li>
    </ul>

    <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          <AlertTitle>{alertSeverity.charAt(0).toUpperCase() + alertSeverity.slice(1)}</AlertTitle>
          {alertMessage}
        </Alert>
      </Snackbar>
          </div>
        </div>
      </footer>
      <div className='footer-bottom'>
        Designed and Developed by{" "}
        <a href='https://praveenpremasinghe-me.netlify.app/'>
          {" "}
          Zero I N D E X{" "}
        </a>{" "}
        &copy; 2024 All Rights Reserved
      </div>
    </>
  );
}

export default Footer;
