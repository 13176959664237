import React, { useState, useEffect } from "react";
import "../styles/main.css";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { CircularProgress } from "@mui/material";
 
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Container,
  Stack,
  Button,
  Divider 
} from "@mui/material";

const BlogCompo = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const firestore = getFirestore();
  const storage = getStorage();
  const MAX_LENGTH = 200;

  const handleCardClick = (id) => {
    navigate(`/blog/${id}`);
  };

  const truncateContent = (content, maxLength) => {
    if (content.length <= maxLength) return content;
    return content.slice(0, maxLength) + '...';
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true); 
        const blogCollection = collection(firestore, "blogs");
        const blogSnapshot = await getDocs(blogCollection);
        const blogList = blogSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(blogList);
      } catch (error) {
        console.error("Error fetching blogs: ", error);
      }
      finally {
        setLoading(false); // End loading
      }
    };

    fetchBlogs();
  }, [firestore]);

 

  return (
    <Container maxWidth='xl' sx={{ marginTop: 15, marginBottom: 10 }}>
      <h2 className='blog-title'>Blogs</h2>

      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <CircularProgress />  
        </div>
      ) : (
      <Stack>
        <div className='blog-main'>
          {blogs.map((blog) => (
            <Card className='blog-card' key={blog.id}>
              {blog.imageUrl && (
                <CardMedia
                  component='img'
                  alt={blog.title}
                  className='blog-card-image'
                  image={blog.imageUrl}
                />
              )}
              <CardContent sx={{ p: 0 }} className='blog-card-content'>
                <Typography
                  variant='h5'
                  component='div'
                  className='blog-card-title'
                >
                  {blog.title}
                </Typography>
             
                <Typography variant='body2' className='blog-card-body'>
                <div dangerouslySetInnerHTML={{ __html: truncateContent(blog.content, MAX_LENGTH) }} />
        </Typography>

     

        <Typography className="small-left-aligned-text">
  {blog.createDate
    ? new Date(blog.createDate.seconds * 1000).toDateString()
    : "No Date"}
</Typography>


              </CardContent>
              <div className='blog-card-footer'>
                <Button
                
                 
                  onClick={() => handleCardClick(blog.id)}
                >
                  Read More
                </Button>
              </div>
            </Card>
          ))}
        </div>
      </Stack>
     )}
    </Container>
  );
};

export default BlogCompo;
