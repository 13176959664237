import React, { useState, useEffect } from "react";
import { LinearProgress } from "@mui/material";
import Logo from "../images/logo/Ezcarrentals-logo.png";

function LoadingProgress() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    window.addEventListener("load", () => {
      setLoading(false);
    });

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='loading-progress'>
      <img src={Logo} alt='EZ Car Rentals Logo' />
      {loading ? (
        <LinearProgress color='secondary' variant='indeterminate' />
      ) : null}
    </div>
  );
}

export default LoadingProgress;
